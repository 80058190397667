<template>
  <div>
    <h1 class="header">
      Internal Reports
    </h1>
    <el-tabs
      :value="selectedTab"
      @tab-click="handleTabClick"
    >
      <el-tab-pane
        v-for="tab in tabs"
        :key="tab.route"
        :label="tab.label"
        :name="tab.route"
      />
      <keep-alive>
        <router-view :key="$route.fullPath" />
      </keep-alive>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'InternalReports',
  data () {
    return {
      tabs: [
        { label: 'Unsent Emails', route: 'UnsentEmailReports', path: 'unsent-email-reports' },
        { label: 'Property Reports', route: 'PropertyReports', path: 'property-reports' }
      ]
    }
  },
  computed: {
    selectedTab () {
      // if current route name matches one of the tabs, return the route name
      if (this.tabs.some(tab => tab.route === this.$route.name)) {
        return this.$route.name
      }
      // if current route is a child route of one of the tabs, return the parent tab
      for (const tab of this.tabs) {
        if (this.$route.path.includes(tab.path)) {
          return tab.route
        }
      }
      return undefined
    }
  },
  methods: {
    /**
     * Handler for when a tab is clicked
     *
     * @param {Object} event
     */
    handleTabClick (event) {
      this.$router.push({ name: event.name }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 2rem;
  margin: 0 0 0.5em;
}
</style>
